import {RateActionTypes} from './constants';

const INIT_STATE = {
    loading: false,
    error: "",
    validationErrors: {},

    rates: [],

    statusLoading: false,
    statusError: "",
    statusValidationErrors: "",
    status: "",

    varianceLoading: false,
    varianceError: "",
    varianceValidationErrors: "",
    variances: {},
    varianceUpdated: false,

    priceLoading: false,
    priceError: "",
    priceValidationErrors: "",
    priceUpdated: false
};

type RateActionType = {
    type:
        | RateActionTypes.API_RESPONSE_SUCCESS
        | RateActionTypes.API_RESPONSE_ERROR
        | RateActionTypes.API_RESPONSE_VALIDATION_ERRORS

        | RateActionTypes.RESET

        | RateActionTypes.GET_RATES

        | RateActionTypes.GET_API_PROVIDER_STATUS
        | RateActionTypes.UPDATE_API_PROVIDER_STATUS
        | RateActionTypes.GET_VARIANCES
        | RateActionTypes.UPDATE_VARIANCES

        | RateActionTypes.UPDATE_PRICE;

    payload: {
        actionType?: string;
        data?: any;
        error?: any;
        validationErrors?: any;
    };
};

type State = {
    rates: any;
};

const Rate = (state: State = INIT_STATE, action: RateActionType) => {
    switch (action.type) {
        case RateActionTypes.API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {

                case RateActionTypes.GET_RATES: {
                    return {
                        ...state,
                        rates: action.payload.data,
                        loading: false,
                    };
                }

                case RateActionTypes.GET_API_PROVIDER_STATUS: {
                    return {
                        ...state,
                        status: action.payload.data,
                        loading: false,
                    };
                }
                case RateActionTypes.UPDATE_API_PROVIDER_STATUS: {
                    return {
                        ...state,
                        statusUpdated: action.payload.data,
                        statusLoading: false,
                    };
                }
                case RateActionTypes.GET_VARIANCES: {
                    return {
                        ...state,
                        variances: action.payload.data,
                        varianceLoading: false,
                    };
                }
                case RateActionTypes.UPDATE_VARIANCES: {
                    return {
                        ...state,
                        varianceUpdated: action.payload.data,
                        varianceLoading: false,
                    };
                }
                case RateActionTypes.UPDATE_PRICE: {
                    return {
                        ...state,
                        priceUpdated: action.payload.data,
                        priceLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case RateActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case RateActionTypes.GET_RATES:
                case RateActionTypes.GET_API_PROVIDER_STATUS:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case RateActionTypes.UPDATE_API_PROVIDER_STATUS:
                {
                    return {
                        ...state,
                        statusError: action.payload.error,
                        statusLoading: false,
                    };
                }
                case RateActionTypes.GET_VARIANCES:
                case RateActionTypes.UPDATE_VARIANCES:
                {
                    return {
                        ...state,
                        varianceError: action.payload.error,
                        varianceLoading: false,
                    };
                }
                case RateActionTypes.UPDATE_PRICE:
                {
                    return {
                        ...state,
                        priceError: action.payload.error,
                        priceLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case RateActionTypes.API_RESPONSE_VALIDATION_ERRORS:
            switch (action.payload.actionType) {
                case RateActionTypes.GET_RATES:
                case RateActionTypes.GET_API_PROVIDER_STATUS:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case RateActionTypes.UPDATE_API_PROVIDER_STATUS:
                {
                    return {
                        ...state,
                        statusValidationErrors: action.payload.validationErrors,
                        statusLoading: false,
                    };
                }
                case RateActionTypes.GET_VARIANCES:
                case RateActionTypes.UPDATE_VARIANCES:
                {
                    return {
                        ...state,
                        varianceValidationErrors: action.payload.validationErrors,
                        varianceLoading: false,
                    };
                }
                case RateActionTypes.UPDATE_PRICE:
                {
                    return {
                        ...state,
                        priceValidationErrors: action.payload.validationErrors,
                        priceLoading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case RateActionTypes.RESET:
            return {
                ...state,

                loading: false,
                error: "",
                validationErrors: {},

                rates: [],
                status: "",

                statusUpdated: false,
                statusLoading: false,
                statusError: "",
                statusValidationErrors: "",

                varianceLoading: false,
                varianceError: "",
                variances: {},
                varianceUpdated: false,

                priceLoading: false,
                priceError: "",
                priceValidationErrors: "",
                priceUpdated: false
            };

        case RateActionTypes.GET_RATES:
            return {
                ...state,
                loading: true,
                error: "",
                rates: []
            };
        case RateActionTypes.GET_API_PROVIDER_STATUS:
            return {
                ...state,
                loading: true,
                error: "",
                status: ""
            };
        case RateActionTypes.UPDATE_API_PROVIDER_STATUS:
            return {
                ...state,
                statusLoading: true,
                statusError: "",
                statusUpdated: false
            };

        case RateActionTypes.GET_VARIANCES:
            return {
                ...state,
                varianceLoading: true,
                varianceError: "",
                variances: {}
            };

        case RateActionTypes.UPDATE_VARIANCES:
            return {
                ...state,
                varianceLoading: true,
                varianceError: "",
                varianceUpdated: false
            };

        case RateActionTypes.UPDATE_PRICE:
            return {
                ...state,
                priceLoading: true,
                priceError: "",
                priceUpdated: false
            };

        default:
            return { ...state };
    }
};

export default Rate;
