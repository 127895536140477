
export enum RateActionTypes {
    API_RESPONSE_SUCCESS = '@@rate/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@rate/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@rate/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@rate/RESET',

    GET_RATES = '@@rate/GET_RATES',
    GET_API_PROVIDER_STATUS = '@@rate/GET_API_PROVIDER_STATUS',
    UPDATE_API_PROVIDER_STATUS = '@@rate/UPDATE_API_PROVIDER_STATUS',

    GET_VARIANCES = '@@rate/GET_VARIANCES',
    UPDATE_VARIANCES = '@@rate/UPDATE_VARIANCES',

    UPDATE_PRICE = '@@rate/UPDATE_PRICE',

}

export enum RateErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



