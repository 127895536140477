import {CRActionTypes} from './constants';

export type CRActionType = {
    type:
        | CRActionTypes.API_RESPONSE_SUCCESS
        | CRActionTypes.API_RESPONSE_ERROR
        | CRActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | CRActionTypes.RESET
        | CRActionTypes.RATES
        | CRActionTypes.CREATE
        | CRActionTypes.UPDATE;
    payload: {} | string;
};

type CR = {
    id: number;
    name: string;
    guard_name: string;
};

class CRActions {

    apiResponseSuccess = (actionType: string, data: CR | {}): CRActionType => ({
        type: CRActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): CRActionType => ({
        type: CRActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): CRActionType => ({
        type: CRActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): CRActionType => ({
        type: CRActionTypes.RESET,
        payload: {},
    });

    getRates = (): CRActionType => ({
        type: CRActionTypes.RATES,
        payload: {},
    });

    create = (data: any): CRActionType => ({
        type: CRActionTypes.CREATE,
        payload: {data},
    });

    update = (data: any): CRActionType => ({
        type: CRActionTypes.UPDATE,
        payload: {data},
    });
}

const crActions = new CRActions();
export { crActions };
