export enum CRActionTypes {
    API_RESPONSE_SUCCESS = '@@cr/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@cr/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@cr/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@cr/RESET',

    RATES = '@@cr/RATES',
    CREATE = '@@cr/CREATE',
    UPDATE = '@@cr/UPDATE',
}

export enum CRErrors {
    RESPONSE_200 = 'Unfortunately, an error has occrrred. Please try again.'
}



