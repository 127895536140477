import { CRActionTypes } from './constants';

const INIT_STATE = {
    error: "",
    loading: false,
    conversionRates: [],
    inserted: false,
    unitDeleted: false,
};

type CRActionType = {
    type:
        | CRActionTypes.API_RESPONSE_SUCCESS
        | CRActionTypes.API_RESPONSE_ERROR
        | CRActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | CRActionTypes.RATES
        | CRActionTypes.CREATE
        | CRActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: boolean,
    conversionRates: string[]
};

const CR = (state: State = INIT_STATE, action: CRActionType) => {
    switch (action.type) {
        case CRActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case CRActionTypes.RATES: {
                    return {
                        ...state,
                        conversionRates: action.payload.data,
                        loading: false,
                    };
                }

                case CRActionTypes.CREATE: {
                    return {
                        ...state,
                        inserted: true,
                        loading: false,
                    };
                }

                case CRActionTypes.UPDATE: {
                    return {
                        ...state,
                        inserted: true,
                        loading: false,
                        conversionRates: [
                            ...state.conversionRates,
                            action.payload.data
                        ],
                        conversionRates1: state.conversionRates.filter(function(item) {
                            return item !== action.payload.data
                        })
                    };
                }
                default:
                    return { ...state };
            }

        case CRActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case CRActionTypes.RATES:
                case CRActionTypes.CREATE:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case CRActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case CRActionTypes.RATES: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case CRActionTypes.CREATE:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CRActionTypes.RATES:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case CRActionTypes.CREATE:
            return {
                ...state,
                inserted: false,
                loading: true,
                error: "",
            };

        case CRActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: "",

                conversionRates: [],
                inserted: false,
                unitDeleted: false,
            };

        default:
            return { ...state };
    }
};

export default CR;
