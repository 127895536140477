import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import settingSaga from './setting/saga';
import userSaga from './user/saga';
import roleSaga from './role/saga';
import permissionSaga from './permission/saga';
import accountSecuritySaga from './myAccount/accountSecurity/saga';
import apiServiceSaga from './myAccount/apiService/saga';
import profileSaga from './myAccount/profile/saga';
import profileImageSaga from './myAccount/userImage/saga';
import personalDetail from './personalDetails/saga';
import mobileNumber from './mobileNumber/saga';
import bankCardSaga from './bankCard/saga';
import ibanSaga from './iban/saga';
import tacSaga from './termsAndConditions/saga';
import walletSaga from './wallet/saga';
import depositSaga from './deposit/saga';
import withdrawSaga from './withdraw/saga';
import tokenSaga from './token/saga';
import transactionSaga from './transaction/saga';
import paymentsSaga from './payment/saga';
import transferSaga from './transfer/saga';
import exchangeSaga from './exchange/saga';
import depositWithdrawSaga from './depositWithdraw/saga';
import productSaga from './product/saga';
import NCFrontSaga from './NCFront/saga';
import NCBackSaga from './NCBack/saga';
import passportSaga from './passport/saga';
import drivingLicenceSaga from './drivingLicence/saga';
import logSaga from './log/saga';
import manageVerificationSaga from './manageVerification/saga';
import dashboardSaga from './dashboard/saga';
import rateSaga from './rate/saga';
import myAccountSaga from './myAccount/myAccount/saga';
import ipSaga from './ip/saga';
import cuSaga from './conversionUnit/saga';
import crSaga from './conversionRate/saga';
import providerSaga from './provider/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(),
        settingSaga(),
        userSaga(),
        roleSaga(),
        permissionSaga(),
        accountSecuritySaga(),
        apiServiceSaga(),
        profileSaga(),
        profileImageSaga(),
        personalDetail(),
        mobileNumber(),
        bankCardSaga(),
        ibanSaga(),
        tacSaga(),
        walletSaga(),
        depositSaga(),
        withdrawSaga(),
        tokenSaga(),
        transactionSaga(),
        paymentsSaga(),
        transferSaga(),
        exchangeSaga(),
        depositWithdrawSaga(),
        productSaga(),
        NCFrontSaga(),
        NCBackSaga(),
        passportSaga(),
        drivingLicenceSaga(),
        logSaga(),
        manageVerificationSaga(),
        dashboardSaga(),
        rateSaga(),
        myAccountSaga(),
        ipSaga(),
        cuSaga(),
        crSaga(),
        providerSaga(),
    ]);
}
